export default {
  "[微笑]": "https://file.suntaoblog.com/emoji/smile.jpg",
  "[笑]": "https://file.suntaoblog.com/emoji/dx.jpg",
  "[呲牙]": "https://file.suntaoblog.com/emoji/cy.jpg",
  "[好的]": "https://file.suntaoblog.com/emoji/ok.jpg",
  "[星星眼]": "https://file.suntaoblog.com/emoji/xxy.jpg",
  "[哦呼]": "https://file.suntaoblog.com/emoji/oh.jpg",
  "[嫌弃]": "https://file.suntaoblog.com/emoji/xq.jpg",
  "[喜欢]": "https://file.suntaoblog.com/emoji/xh.jpg",
  "[酸了]": "https://file.suntaoblog.com/emoji/sl.jpg",
  "[大哭]": "https://file.suntaoblog.com/emoji/dq.jpg?",
  "[害羞]": "https://file.suntaoblog.com/emoji/hx.jpg",
  "[无语]": "https://file.suntaoblog.com/emoji/wy.jpg",
  "[疑惑]": "https://file.suntaoblog.com/emoji/yh.jpg",
  "[调皮]": "https://file.suntaoblog.com/emoji/tiaopi.jpg",
  "[笑哭]": "https://file.suntaoblog.com/emoji/xiaoku.jpg",
  "[奸笑]": "https://file.suntaoblog.com/emoji/jianxiao.jpg",
  "[偷笑]": "https://file.suntaoblog.com/emoji/touxiao.jpg",
  "[大笑]": "https://file.suntaoblog.com/emoji/daxiao.jpg",
  "[阴险]": "https://file.suntaoblog.com/emoji/yinxian.jpg",
  "[捂脸]": "https://file.suntaoblog.com/emoji/wulian.jpg",
  "[呆]": "https://file.suntaoblog.com/emoji/dai.jpg",
  "[抠鼻]": "https://file.suntaoblog.com/emoji/koubi.jpg",
  "[惊喜]": "https://file.suntaoblog.com/emoji/jingxi.jpg",
  "[惊讶]": "https://file.suntaoblog.com/emoji/jingya.jpg",
  "[捂脸哭]": "https://file.suntaoblog.com/emoji/wulianku.jpg",
  "[妙啊]": "https://file.suntaoblog.com/emoji/miaoa.jpg",
  "[狗头]": "https://file.suntaoblog.com/emoji/goutou.jpg",
  "[滑稽]": "https://file.suntaoblog.com/emoji/huaji.jpg",
  "[吃瓜]": "https://file.suntaoblog.com/emoji/chigua.jpg",
  "[打call]": "https://file.suntaoblog.com/emoji/dacall.jpg",
  "[点赞]": "https://file.suntaoblog.com/emoji/dianzan.jpg",
  "[鼓掌]": "https://file.suntaoblog.com/emoji/guzhang.jpg",
  "[尴尬]": "https://file.suntaoblog.com/emoji/ganga.jpg",
  "[冷]": "https://file.suntaoblog.com/emoji/leng.jpg",
  "[灵魂出窍]": "https://file.suntaoblog.com/emoji/linghunchuqiao.jpg",
  "[委屈]": "https://file.suntaoblog.com/emoji/weiqu.jpg",
  "[傲娇]": "https://file.suntaoblog.com/emoji/aojiao.jpg",
  "[疼]": "https://file.suntaoblog.com/emoji/teng.jpg",
  "[吓]": "https://file.suntaoblog.com/emoji/xia.jpg?",
  "[生病]": "https://file.suntaoblog.com/emoji/shengbing.jpg",
  "[吐]": "https://file.suntaoblog.com/emoji/tu.jpg",
  "[嘘声]": "https://file.suntaoblog.com/emoji/xusheng.jpg",
  "[捂眼]": "https://file.suntaoblog.com/emoji/wuyan.jpg",
  "[思考]": "https://file.suntaoblog.com/emoji/sikao.jpg",
  "[再见]": "https://file.suntaoblog.com/emoji/zaijian.jpg",
  "[翻白眼]": "https://file.suntaoblog.com/emoji/fanbaiyan.jpg",
  "[哈欠]": "https://file.suntaoblog.com/emoji/haqian.jpg",
  "[奋斗]": "https://file.suntaoblog.com/emoji/fengdou.jpg",
  "[墨镜]": "https://file.suntaoblog.com/emoji/mojing.jpg",
  "[撇嘴]": "https://file.suntaoblog.com/emoji/piezui.jpg",
  "[难过]": "https://file.suntaoblog.com/emoji/nanguo.jpg",
  "[抓狂]": "https://file.suntaoblog.com/emoji/zhuakuang.jpg",
  "[生气]": "https://file.suntaoblog.com/emoji/shengqi.jpg",
  "[爱心]": "https://file.suntaoblog.com/emoji/aixin.jpg",
  "[胜利]": "https://file.suntaoblog.com/emoji/shengli.jpg",
  "[保佑]": "https://file.suntaoblog.com/emoji/baoyou.jpg",
  "[支持]": "https://file.suntaoblog.com/emoji/zhichi.jpg"
};
